import crm from './crm';
import page from './page';
import ClientReservation from './ClientReservation';
import popup from './lib/popup';

export default class {
  constructor(reservation) {
    this.id = reservation.id ? reservation.id : null;
    this.hash = reservation.hash ? reservation.hash : null;
    this.client = reservation.client ? reservation.client : null;
    this.client_reservation = reservation.client_reservation ? reservation.client_reservation : null;
    this.room = reservation.room ? reservation.room : null;
    this.arrival = reservation.arrival ? reservation.arrival : null;
    this.departure = reservation.departure ? reservation.departure : null;
    this.date = reservation.date ? reservation.date : null;
    this.adults = reservation.adults ? reservation.adults : '';
    this.child_s = reservation.child_s ? reservation.child_s : '';
    this.child_d = reservation.child_d ? reservation.child_d : '';
    this.status = {
      name: reservation.status.name ? reservation.status.name : null,
      value: reservation.status.value ? reservation.status.value : null
    };
    this.comment = reservation.comment ? reservation.comment : '';
    this.total = reservation.total ? reservation.total : '';
    this.sale = reservation.sale ? reservation.sale : null;
    this.total_sale = reservation.total_sale ? reservation.total_sale : null;
    this.pay = reservation.pay ? reservation.pay : null;
    this.payday = reservation.payday ? reservation.payday : null;
    this.seven_sended = reservation.seven_sended ? reservation.seven_sended : null;
  }
  static add(client) {
    statusArr = [{
        name: `заселен`,
        value: `inhabited`
      },
      {
        name: `бронь`,
        value: `booked`
      },
      {
        name: `отменен`,
        value: `canceled`
      },
      {
        name: `выехал`,
        value: `left`
      },
    ];
    let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Изменить бронь</h4>
    <div>
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-md-4 control-label">Номер</label>
          <div class="col-md-8">
            <select class="form-control add-reservation-popup-room">`;
    crm.rooms.forEach(room => {
      HTML += `<option value="${room.id}">${room.name}</option>`;
    });
    HTML += `</select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Приезд</label>
          <div class="col-md-8">
            <div class="input-group add-reservation-datepicker">
              <input type="text" class="form-control add-reservation-popup-arrival">
              <span class="input-group-addon">
                <span class="icon-calendar-full"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Отъезд</label>
          <div class="col-md-8">
            <div class="input-group add-reservation-datepicker">
              <input type="text" class="form-control add-reservation-popup-departure">
              <span class="input-group-addon">
                <span class="icon-calendar-full"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Взрослые</label>
          <div class="col-md-8">
            <input type="text" class="form-control add-reservation-popup-adults">
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Дети после двух лет</label>
          <div class="col-md-8">
            <input type="text" class="form-control add-reservation-popup-child_s">
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Дети до двух лет</label>
          <div class="col-md-8">
            <input type="text" class="form-control add-reservation-popup-child_d">
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Статус</label>
          <div class="col-md-8">
            <select class="form-control add-reservation-popup-status">`;
    statusArr.forEach(status => {
      HTML += `<option value="${status.value}">${status.name}</option>`;
    });
    HTML += `</select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Комментарий</label>
          <div class="col-md-8">
            <textarea class="form-control add-reservation-popup-comment" rows="5"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label">Сумма</label>
          <div class="col-md-8">
            <input type="text" class="form-control add-reservation-popup-total">
          </div>
        </div>
      </form>
      <button class="btn btn-primary add-reservation-popup-btn" style="margin:20px 30px; float:right;">Добавить</button>
    </div>`;
    let addReservationPopup = popup(HTML, {
      class: 'add-reservation-popup',
      radius: '5px'
    });
    $('.add-reservation-datepicker').datetimepicker({
      format: 'DD/MM/YYYY'
    });
    let self = this;
    let addBtn = document.querySelector('.add-reservation-popup-btn');
    addBtn.addEventListener('click', () => {
      let room = document.querySelector('.add-reservation-popup-room');
      let arrival = document.querySelector('.add-reservation-popup-arrival');
      let departure = document.querySelector('.add-reservation-popup-departure');
      let adults = document.querySelector('.add-reservation-popup-adults');
      let child_s = document.querySelector('.add-reservation-popup-child_s');
      let child_d = document.querySelector('.add-reservation-popup-child_d');
      let status = document.querySelector('.add-reservation-popup-status');
      let statusValue = status.value;
      let statusName = status.options[status.selectedIndex].innerHTML;
      let comment = document.querySelector('.add-reservation-popup-comment');
      let total = document.querySelector('.add-reservation-popup-total');
      let roomObj;
      crm.rooms.forEach(_room => {
        if (_room.id === room.value) {
          roomObj = _room;
        }
      });
      addReservationPopup.remove();
      loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
        background: 'transparent'
      });
      let now = new Date();
      let day = now.getDate() <= 9 ? '0' + now.getDate() : now.getDate();
      let month = (now.getMonth() + 1) <= 9 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1);
      let dateString = day + '/' + month + '/' + now.getFullYear();
      let arrivalSplit = arrival.value.split('/');
      mn.object('client_reservations').elements.add({
        properties: {
          year: arrivalSplit[2],
          arrival: arrival.value,
          departure: departure.value,
          pay: 'n'
        },
        callback: (response) => {
          let responseObj = JSON.parse(response);
          let clientReservation = new ClientReservation({
            id: responseObj.id_element,
            hash: responseObj.hash_element,
            year: arrivalSplit[2],
            arrival: arrival.value,
            departure: departure.value,
            pay: {
              name: `нет`,
              value: 'n'
            },
            date: day + '.' + month + '.' + now.getFullYear()
          });
          if (!crm.clientReservations) crm.clientReservations = [];
          crm.clientReservations.push(clientReservation);
          let clientReservationsArr = [];
          if (client.client_reservations) {
            client.client_reservations.forEach((item) => {
              clientReservationsArr.push(item);
            });
          }
          clientReservationsArr.push(clientReservation);
          client.client_reservations = clientReservationsArr;
          let clientReservationIdArr = [];
          clientReservationsArr.forEach((clientReservation) => {
            clientReservationIdArr.push(clientReservation.id);
          });
          Promise.all([
            new Promise((resolve, reject) => {
              mn.object('clients').elements.edit({
                elements: [[client.id, client.hash]],
                properties: {
                  client_reservations: clientReservationIdArr
                },
                callback: (clientResponse) => {
                  resolve(clientResponse);
                }
              });
            }),
            new Promise((resolve, reject) => {
              mn.object('reservation').elements.add({
                properties: {
                  client: client.id,
                  client_reservation: clientReservation.id,
                  room: room.value,
                  arrival: arrival.value,
                  departure: departure.value,
                  date: dateString,
                  adults: adults.value,
                  child_s: child_s.value,
                  child_d: child_d.value,
                  status: statusValue,
                  comment: comment.value,
                  total: total.value,
                },
                callback: (reservationResponse) => {
                  resolve(reservationResponse);
                }
              });
            })
          ]).then((results) => {
            let reservationObj = JSON.parse(results[1]);
            if (!crm.reservations) crm.reservations = [];
            crm.reservations.push(new this({
              id: reservationObj.id_element,
              hash: reservationObj.hash_element,
              client: client,
              client_reservation: clientReservation,
              room: roomObj,
              arrival: arrival.value,
              departure: departure.value,
              date: dateString,
              adults: adults.value,
              child_s: child_s.value,
              child_d: child_d.value,
              status: {
                name: statusName,
                value: statusValue
              },
              comment: comment.value,
              total: total.value,
              // sale: reservation.sale,
              // total_sale: reservation.total_sale,
              // pay: reservation.pay,
              // payday: reservation.payday
            }));
            page.reDrawClients();
            page.reDrawReservations();
            page.reInitCalendar();
            loadingPopup.remove();
          });
        }
      });
    });
  }
  draw() {
    let HTML = `<td>
      <div class="contact contact-rounded contact-bordered contact-lg">
        <img src="${this.client.photo ? this.client.photo : 'crm/img/user/no-image.png'}">
        <div class="contact-container">
          <a href="#">${this.client.name}</a>
          <span style="min-height:20px;">${this.client.city}</span>
        </div>
      </div>
    </td>
    <td>${this.arrival}</td>
    <td style="position:relative;">
      <span class="label label-${this.status.value} label-bordered">${this.status.name}</span>
      <span class="icon-checkmark-circle is-pay-icon is-pay-${this.pay}" style=position:absolute;top:20px;right:20px;></span>
    </td>
    <td>
      <div class="dropdown">
        <button class="btn btn-default btn-icon btn-clean dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="icon-cog"></span>
        </button>
        <ul class="dropdown-menu dropdown-left">
          <li>
            <a style="cursor:pointer" class="reservations-pay-btn" data-id="${this.id}" data-hash="${this.hash}" data-pay="${this.pay}">
              <span class="icon-checkmark-circle text-info "></span>
              <span>${this.pay === `y` ? `Оплачено` : `Не оплачено`}</span>
            </a>
          </li>
          <li>
            <a style="cursor:pointer" class="reservations-edit-btn" data-id="${this.id}" data-hash="${this.hash}">
              <span class="icon-menu-circle text-info "></span>Редактировать</a>
          </li>
          <li>
            <a style="cursor:pointer" class="reservations-delete-btn" data-id="${this.id}" data-hash="${this.hash}">
              <span class="icon-cross-circle text-danger"></span>Удалить</a>
          </li>
        </ul>
      </div>
    </td>`;
    let tr = document.createElement('tr');
    tr.classList.add('mp-reservations-item');
    tr.dataset.id = this.id;
    tr.dataset.hash = this.hash;
    tr.innerHTML = HTML;

    let editBtn = tr.querySelector('.reservations-edit-btn');
    this.edit(editBtn);
    let deleteBtn = tr.querySelector('.reservations-delete-btn');
    this.delete(deleteBtn);
    let changePayBtn = tr.querySelector('.reservations-pay-btn');
    this.changePay(changePayBtn);
    return tr;
  }
  edit(btn) {
    let self = this;
    btn.addEventListener('click', () => {
      statusArr = [{
          name: `заселен`,
          value: `inhabited`
        },
        {
          name: `бронь`,
          value: `booked`
        },
        {
          name: `отменен`,
          value: `canceled`
        },
        {
          name: `выехал`,
          value: `left`
        },
      ];
      let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Редактировать бронь</h4>
      <div>
        <form class="form-horizontal">
          <div class="form-group">
            <label class="col-md-4 control-label">Номер</label>
            <div class="col-md-8">
              <select class="form-control edit-reservation-popup-room">`;
      crm.rooms.forEach(room => {
        if (room.id === self.room.id) {
          HTML += `<option value="${self.room.id}" selected>${self.room.name}</option>`;
        } else {
          HTML += `<option value="${room.id}">${room.name}</option>`;
        }
      });
      HTML += `</select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Приезд</label>
            <div class="col-md-8">
              <div class="input-group edit-reservation-datepicker">
                <input type="text" class="form-control edit-reservation-popup-arrival" value="${self.arrival}">
                <span class="input-group-addon">
                  <span class="icon-calendar-full"></span>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Отъезд</label>
            <div class="col-md-8">
              <div class="input-group edit-reservation-datepicker">
                <input type="text" class="form-control edit-reservation-popup-departure" value="${self.departure}">
                <span class="input-group-addon">
                  <span class="icon-calendar-full"></span>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Взрослые</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-reservation-popup-adults" value="${self.adults}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Дети после двух лет</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-reservation-popup-child_s" value="${self.child_s}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Дети до 2 лет</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-reservation-popup-child_d" value="${self.child_d}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Статус</label>
            <div class="col-md-8">
              <select class="form-control edit-reservation-popup-status">`;
      statusArr.forEach(status => {
        if (status.value === self.status.value) {
          HTML += `<option value="${self.status.value}" selected>${self.status.name}</option>`;
        } else {
          HTML += `<option value="${status.value}">${status.name}</option>`;
        }
      });
      HTML += `</select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Комментарий</label>
            <div class="col-md-8">
              <textarea class="form-control edit-reservation-popup-comment" rows="5">${self.comment}</textarea>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Сумма</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-reservation-popup-total" value="${self.total}">
            </div>
          </div>
        </form>
        <button class="btn btn-primary edit-reservation-popup-btn" style="margin:20px 30px; float:right;">Изменить</button>
      </div>`;
      let editReservationPopup = popup(HTML, {
        class: 'edit-reservation-popup',
        radius: '5px'
      });
      $('.edit-reservation-datepicker').datetimepicker({
        format: 'DD/MM/YYYY'
      });
      let confirmBtn = editReservationPopup.querySelector('.edit-reservation-popup-btn');
      confirmBtn.addEventListener('click', () => {
        let roomId = document.querySelector('.edit-reservation-popup-room').value;
        let arrival = document.querySelector('.edit-reservation-popup-arrival').value;
        let departure = document.querySelector('.edit-reservation-popup-departure').value;
        let adults = document.querySelector('.edit-reservation-popup-adults').value;
        let child_s = document.querySelector('.edit-reservation-popup-child_s').value;
        let child_d = document.querySelector('.edit-reservation-popup-child_d').value;
        let status = document.querySelector('.edit-reservation-popup-status');
        let statusValue = status.value;
        let statusName = status.options[status.selectedIndex].innerHTML;
        let comment = document.querySelector('.edit-reservation-popup-comment').value;
        let total = document.querySelector('.edit-reservation-popup-total').value;
        editReservationPopup.remove();
        loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
          background: 'transparent'
        });
        let roomObj;
        crm.rooms.forEach(room => {
          if (room.id === roomId) {
            roomObj = room;
          }
        });
        Promise.all([
          new Promise((resolve, reject) => {
            mn.object('reservation').elements.edit({
              elements: [
                [self.id, self.hash]
              ],
              properties: {
                room: roomId,
                arrival: arrival,
                departure: departure,
                adults: adults,
                child_s: child_s,
                child_d: child_d,
                status: statusValue,
                comment: comment,
                total: total
              },
              callback: (response) => {
                resolve(response);
              }
            });
          }),
          new Promise((resolve, reject) => {
            mn.object('client_reservations').elements.edit({
              elements: [
                [self.client_reservation.id, self.client_reservation.hash]
              ],
              properties: {
                arrival: arrival,
                departure: departure
              },
              callback: (response) => {
                resolve(response);
              }
            });
          })
        ]).then(results => {
          self.room = roomObj;
          self.arrival = arrival;
          self.departure = departure;
          self.adults = adults;
          self.child_s = child_s;
          self.child_d = child_d;
          self.status = {
              name: statusName,
              value: statusValue
            },
            self.comment = comment;
          self.total = total;
          self.client_reservation.arrival = arrival;
          self.client_reservation.departure = departure;
          page.reDrawReservations();
          page.reInitCalendar();
          page.reDrawClients();
          loadingPopup.remove();
        });
      });
    });
  }
  delete(btn) {
    let self = this;
    btn.addEventListener('click', () => {
      let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Вы уверены?</h4><div style="text-align:center;padding:30px;"><button class="btn btn-danger delete-client-popup-yes-btn">Да</button><button class="btn btn-primary delete-client-popup-no-btn">Нет</button></div>`;
      let confirmPopup = popup(HTML, {
        class: 'confirm-delete-client-popup',
        radius: '5px'
      });
      let yesBtn = document.querySelector('.delete-client-popup-yes-btn');
      let noBtn = document.querySelector('.delete-client-popup-no-btn');
      noBtn.addEventListener('click', () => {
        confirmPopup.remove();
      });
      yesBtn.addEventListener('click', () => {
        confirmPopup.remove();
        loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
          background: 'transparent'
        });
        mn.object('reservation').elements.delete({
          elements: [
            [self.id, self.hash]
          ],
          callback: (response) => {
            crm.reservations.forEach((reservation, i) => {
              if (reservation.id === self.id) {
                crm.reservations.splice(i, 1);
              }
            });
            page.reDrawReservations();
            page.reInitCalendar();
            loadingPopup.remove();
          }
        });
      });
    });
  }
  changePay(btn) {
    let self = this;
    btn.addEventListener('click', () => {
      let loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
        background: 'transparent'
      });
      let dateString;
      if (self.pay !== 'y') {
        let now = new Date();
        let day = now.getDate() <= 9 ? '0' + now.getDate() : now.getDate();
        let month = (now.getMonth() + 1) <= 9 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1);
        dateString = day + '/' + month + '/' + now.getFullYear();
      }
      Promise.all([
        new Promise((resolve, reject) => {
          mn.object('reservation').elements.edit({
            elements: [
              [self.id, self.hash]
            ],
            properties: {
              pay: self.pay === 'y' ? 'n' : 'y',
              payday: self.pay === 'y' ? null : dateString,
            },
            callback: (response) => {
              if (response) {
                let responseArray = JSON.parse(response);
                resolve(responseArray);
              } else {
                resolve(null);
              }
            }
          });
        }),
        new Promise((resolve, reject) => {
          mn.object('client_reservations').elements.edit({
            elements: [
              [self.client_reservation.id, self.client_reservation.hash]
            ],
            properties: {
              pay: self.pay === 'y' ? 'n' : 'y'
            },
            callback: (response) => {
              if (response) {
                let responseArray = JSON.parse(response);
                resolve(responseArray);
              } else {
                resolve(null);
              }
            }
          });
        })
      ]).then((results) => {
        self.client_reservation.pay.value = self.pay === 'y' ? 'n' : 'y';
        self.client_reservation.pay.name = self.pay === 'y' ? `нет` : `да`;
        self.pay = self.pay === 'y' ? 'n' : 'y';
        page.reDrawReservations();
        page.reDrawClients();
        loadingPopup.remove();
      });
    });
  }
}