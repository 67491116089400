import crm from './crm';

export default class {
  constructor(room) {
    this.id = room.id;
    this.hash = room.hash;
    this.name = room.name;
    this.type = {
      name: room.type.name,
      photo: room.type.photo,
      id: room.type.id
    };
  }
  draw() {
    let status = {
      name: `Свободен`,
      value: 'nothing'
    };
    let statusInhabitedFlag = false;
    let statusBookedFlag = false;
    let amountBooked = 0;
    if (crm.reservations) {
      crm.reservations.forEach(reservation => {
        if (this.id === reservation.room.id) {
          if (reservation.status.value === 'inhabited') {
            statusInhabitedFlag = true;
          } else if (reservation.status.value === 'booked') {
            statusBookedFlag = true;
            amountBooked += reservation.adults * 1;
          }
        }
      });
    } else {
      
    }
    
    if (statusInhabitedFlag) {
      status.name = `Заселен`;
      status.value = 'inhabited';
    } else if (statusBookedFlag) {
      status.name = `Забронирован`;
      status.value = 'booked';
    }
    let HTML = `<div class="col-md-4">
      <div class="tile-basic">
        <div class="tile-image">
          <img src="${this.type.photo}" alt="">
        </div>
        <div class="tile-content">
          <h3 class="tile-title">${this.name}</h3>
          <p>
            <span class="label label-bordered label-${status.value} room-status">${status.name}</span>
            <br>Забронировали:
            <b class="rooms-booked">${amountBooked}</b>
          </p>
        </div>
      </div>
    </div>`;
    return HTML;
  }
}