export default function(content, param) {
  var param = param ? param : {};
  var popup = document.createElement('div');
  popup.style.display = 'flex';
  popup.style.position = 'fixed';
  popup.style.zIndex = 10000;
  popup.style.top = 0;
  popup.style.right = 0;
  popup.style.bottom = 0;
  popup.style.left = 0;
  popup.style.padding = '20px';
  popup.style.overflowY = 'auto';
  popup.style.backgroundColor = 'rgba(0,0,0,.5)';
  var container = document.createElement('div');
  container.style.margin = 'auto';
  container.style.backgroundColor = '#fff';
  if (param.class) container.classList.add(param.class);
  if (param.background) container.style.backgroundColor = param.background;
  if (param.radius) container.style.borderRadius = param.radius;
  if (param.width) container.style.width = param.width;
  if (param.padding) container.style.padding = param.padding;
  popup.appendChild(container);
  (typeof content === 'string') ? container.innerHTML = content : container.appendChild(content);
  popup.addEventListener('click', function (e) {
      if (e.target === this) {
          this.remove();
          if (param.callback) param.callback();
      }
  });
  document.body.appendChild(popup);
  return popup;
}