export default class {
  constructor(clientReservation) {
    this.id = clientReservation.id;
    this.hash = clientReservation.hash;
    this.year = clientReservation.year;
    this.arrival = clientReservation.arrival;
    this.departure = clientReservation.departure;
    this.pay = {
      name: clientReservation.pay.name,
      value: clientReservation.pay.value
    };
    this.date = clientReservation.date;
  }
}