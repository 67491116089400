import crm from './crm';
import site from './site';
import popup from './lib/popup';

let now = new Date();
let year = now.getFullYear();
let type = 'birthday';

crm.init(year).then((results) => {

  crm.makeRooms(results[0]);
  crm.makeClientReservations(results[1]);
  crm.makeClients(results[2]);
  crm.makeReservations(results[3]);
  crm.makePrices(results[5]);
  crm.makeClientDistributions(results[6]);

  site.changeRoomEvent();
  site.initRegions();
  site.addReservationEvent();
  site.distributionsInit();
  site.checkRoomSelectedForDates();

  $('#user-phone').mask("+7 (999) 999-99-99");

  console.log(crm);
});