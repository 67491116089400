export default function (URL, POST, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
      if (xhr.status === 200) {
          if (callback) {
              callback(xhr.response);
          }
      } else {
          console.log(xhr.statusText);
      }
  };
  xhr.onerror = function (error) {
      console.log(error);
  };
  if (POST) {
      xhr.open('POST', URL);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send(POST);
  } else {
      xhr.open('GET', URL);
      xhr.send(null);
  }
}