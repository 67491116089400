import crm from './crm';
import page from './page';
import Reservation from './Reservation';
import popup from './lib/popup';

export default class {
  constructor(client) {
    this.id = client.id ? client.id : null;
    this.hash = client.hash ? client.hash : null;
    this.name = client.name ? client.name : null;
    this.phone = client.phone ? client.phone : '';
    this.email = client.email ? client.email : '';
    this.region = client.region ? client.region : '';
    this.city = client.city ? client.city : '';
    this.birthday = client.birthday ? client.birthday : '';
    this.passport = client.passport ? client.passport : '';
    this.sex = {
      name: client.sex.name ? client.sex.name : '',
      value: client.sex.value ? client.sex.value : null
    };
    this.child = {
      name: client.child.name ? client.child.name : '',
      value: client.child.value ? client.child.value : null
    };
    this.info = client.info ? client.info : '';
    this.photo = client.photo ? client.photo : null;
    this.client_reservations = client.client_reservations ? client.client_reservations : null;
  }
  static add() {
    let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Добавить клиента</h4>
      <div>
        <form class="form-horizontal">
          <div class="form-group" style="margin-top:20px">
            <label class="col-md-4 control-label">ФИО</label>
            <div class="col-md-8">
              <input type="text" class="form-control add-client-popup-name">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">День рождения</label>
            <div class="col-md-8">
              <div class="input-group add-client-popup-datepicker">
                <input type="text" class="form-control add-client-popup-birthday">
                <span class="input-group-addon">
                  <span class="icon-calendar-full"></span>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Паспортные данные</label>
            <div class="col-md-8">
              <input type="text" class="form-control add-client-popup-passport">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Телефон</label>
            <div class="col-md-8">
              <input type="text" class="form-control add-client-popup-phone">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Email</label>
            <div class="col-md-8">
              <input type="email" class="form-control add-client-popup-email">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Регион</label>
            <div class="col-md-8">
              <select class="form-control edit-client-popup-region">`;
    for (var key in crm.regions) {
      HTML += '<option value="' + crm.regions[key].name + '">' + crm.regions[key].name + '</option>';
    }
    HTML += `</select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Город</label>
            <div class="col-md-8">
              <input type="text" class="form-control add-client-popup-city">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Пол</label>
            <div class="col-md-8">
              <select class="form-control add-client-popup-sex">
                <option data-sex="m">мужской</option> 
                <option data-sex="f">женский</option> 
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Дети есть?</label>
            <div class="col-md-8">
              <select class="form-control add-client-popup-child">
                <option data-child="y">есть</option>
                <option data-child="n">нет</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Информация</label>
            <div class="col-md-8">
              <textarea class="form-control add-client-popup-info" rows="5"></textarea>
            </div>
          </div>
        </form>
        <button class="btn btn-primary add-client-popup-btn" style="margin:20px 30px 0 0;">Добавить</button>
      </div>`;
    let addClientPopup = popup(HTML, {
      class: 'add-client-popup',
      radius: '5px'
    });
    $('.add-client-popup-phone').mask('7 (999) 999-99-99');
    $('.add-client-popup-datepicker').datetimepicker({
      format: 'DD/MM/YYYY'
    });
    let self = this;
    let addBtn = document.querySelector('.add-client-popup-btn');
    addBtn.addEventListener('click', function () {
      let name = document.querySelector('.add-client-popup-name');
      let birthday = document.querySelector('.add-client-popup-birthday');
      let passport = document.querySelector('.add-client-popup-passport');
      let phone = document.querySelector('.add-client-popup-phone');
      let email = document.querySelector('.add-client-popup-email');
      let city = document.querySelector('.add-client-popup-city');
      let sex = document.querySelector('.add-client-popup-sex');
      let child = document.querySelector('.add-client-popup-child');
      let info = document.querySelector('.add-client-popup-info');
      let region = document.querySelector('.edit-client-popup-region');
      if (!name.value) {
        alert(`Введите имя`);
        name.focus();
        return false;
      }
      if (!phone.value) {
        alert(`Введите телефон`);
        phone.focus();
        return false;
      }
      let unique = true;
      if (self.clients) {
        self.clients.forEach(client => {
          if (client.phone === phone.value) {
            unique = false;
          }
        });
      }
      if (!unique) {
        alert(`Такой номер телефона уже есть в базе`);
        phone.focus();
        return false;
      }
      addClientPopup.remove();
      let loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
        background: 'transparent'
      });
      mn.object('clients').elements.add({
        properties: {
          name: name.value,
          phone: phone.value,
          email: email.value,
          region: region.value,
          city: city.value,
          birthday: birthday.value,
          passport: passport.value,
          sex: sex.options[sex.selectedIndex].dataset.sex,
          child: child.options[child.selectedIndex].dataset.child,
          info: info.value,
        },
        callback: function (response) {
          let responseObj = JSON.parse(response);
          let client = new self({
            id: responseObj.id_element,
            hash: responseObj.hash_element,
            name: name.value,
            phone: phone.value,
            email: email.value,
            region: region.value,
            city: city.value,
            birthday: birthday.value,
            passport: passport.value,
            sex: {
              name: sex.options[sex.selectedIndex].innerHTML,
              value: sex.options[sex.selectedIndex].dataset.sex
            },
            child: {
              name: child.options[child.selectedIndex].innerHTML,
              value: child.options[child.selectedIndex].dataset.child
            },
            info: info.value
          });
          if (crm.clients) {
            crm.clients.unshift(client);
          } else {
            crm.clients = [];
            crm.clients.push(client);
          }
          page.reDrawClients();
          loadingPopup.remove();
        }
      });
    });
  }
  draw() {
    let isPay, payday;
    if (this.client_reservations) {
      this.client_reservations.forEach((clientReservation) => {
        if (clientReservation.reservation && clientReservation.reservation.pay === 'y') {
          isPay = 'y';
          payday = clientReservation.reservation.payday;
        }
      });
    }
    let HTML = `<div class="row">
      <div class="col-md-3">
        <div class="contact contact-rounded contact-bordered contact-lg status-online margin-bottom-0">
          <img src="${this.photo ? this.photo : 'crm/img/user/no-image.png'}" style="z-index:1000;cursor:pointer" class="client-item-photo" data-id="${this.id}"
            data-hash="${this.hash}">
          <div class="contact-container">
            <a style="cursor:pointer" data-id="${this.id}" data-hash="${this.hash}" class="client-item-name" style="cursor:default;">${this.name}</a>
            <span class="client-item-region">${this.region}</span>
            <span class="client-item-city">${this.city}</span>
          </div>
          <div class="client-item-sex male-female ${this.sex.value}">
            <div class="male">М</div>
            <div class="female">Ж</div>
          </div>
          <span class="icon-checkmark-circle is-pay-icon${isPay === 'y' ? ' is-pay-y' : ''}" style="position:absolute;padding:0;z-index:1000;right:0;top:0;"></span>
        </div>
      </div>
      <div class="col-md-2">
        <strong class="client-item-birthday">${this.birthday}</strong>
        <strong> | Дети:</strong>
        <strong class="client-item-child">${this.child.name}</strong>
        <br>
        <i class="client-item-passport">${this.passport}</i>
      </div>
      <div class="col-md-2">
        <strong class="client-item-phone">${this.phone}</strong>
        <br>
        <i class="client-item-email">${this.email}</i>
        <br>
        <strong class="client-item-payday">${payday ? payday : ''}</strong>
      </div>
      <div class="col-md-3 client-info">
        <div>${this.info}</div>
      </div>
      <div class="col-md-2">
        <div class="dropdown" style="position:absolute;right:5px;">
          <button class="btn btn-default btn-icon btn-clean dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="icon-cog"></span>
          </button>
          <ul class="dropdown-menu dropdown-left">
            <li>
              <a style="cursor:pointer" class="client-item-add-reservation-btn" data-id="${this.id}">
                <span class="icon-checkmark-circle text-info "></span>
                <span>Добавить бронь</span>
              </a>
            </li>
            <li>
              <a style="cursor:pointer" class="client-item-edit-btn" data-id="${this.id}" data-hash="${this.hash}">
                <span class="icon-menu-circle text-info "></span>Редактировать</a>
            </li>
            <li>
              <a style="cursor:pointer;position:relative;right:auto;" class="client-item-delete-btn" data-id="${this.id}" data-hash="${this.hash}">
                <span class="icon-cross-circle text-danger"></span>Удалить</a>
            </li>
          </ul>
        </div>
        <button type="button" class="btn btn-info client-item-reservation-btn" data-id="${this.id}" style="z-index:500;">Брони клиента</button>
      </div>
    </div>`;
    let div = document.createElement('div');
    div.className = 'block-content client-item client-item-for-remove';
    div.dataset.id = this.id;
    div.dataset.hash = this.hash;
    div.dataset.sex = this.sex.value;
    div.dataset.pay = this.pay ? this.pay : '';
    div.dataset.sexShow = 'show';
    div.dataset.payShow = 'show';
    div.dataset.name = this.name;
    div.dataset.phone = this.phone;
    div.dataset.email = this.email;
    div.innerHTML = HTML;
    let divDivider = document.createElement('div');
    divDivider.className = 'block-divider margin-top-0 margin-bottom-20 client-item-for-remove';

    let editBtn = div.querySelector('.client-item-edit-btn');
    this.edit(editBtn);
    let deleteBtn = div.querySelector('.client-item-delete-btn');
    this.delete(deleteBtn);

    let reservationsBtn = div.querySelector('.client-item-reservation-btn');
    this.showReservations(reservationsBtn);

    let addReservationBtn = div.querySelector('.client-item-add-reservation-btn');
    let self = this;
    addReservationBtn.addEventListener('click', function (btn) {
      Reservation.add(self);
    });

    // var clientImg = div.querySelector('.client-item-photo');
    // crm.editClientPhotoEvent(clientImg);
    // var clientReservationsBtn = div.querySelector('.client-item-reservation-btn');
    // crm.showClientReservation(clientReservationsBtn);
    return [div, divDivider];
  }
  edit(btn) {
    let self = this;
    btn.addEventListener('click', () => {
      sexArr = [{
        name: `мужской`,
        value: 'm'
      }, {
        name: `женский`,
        value: 'f'
      }];
      childArr = [{
        name: `есть`,
        value: 'y'
      }, {
        name: `нет`,
        value: 'n'
      }];
      let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Редактировать клиента</h4>
      <div>
        <form class="form-horizontal">
          <div class="form-group">
            <label class="col-md-4 control-label">ФИО</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-client-popup-name" value="${self.name}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">День рождения</label>
            <div class="col-md-8">
              <div class="input-group bs-datepicker edit-client-datepicker">
                <input type="text" class="form-control edit-client-popup-birthday" value="${self.birthday}">
                <span class="input-group-addon">
                  <span class="icon-calendar-full"></span>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Паспортные данные</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-client-popup-passport" value="${self.passport}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Телефон</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-client-popup-phone" value="${self.phone}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Email</label>
            <div class="col-md-8">
              <input type="email" class="form-control edit-client-popup-email" value="${self.email}">
            </div>
          </div>

          <div class="form-group">
            <label class="col-md-4 control-label">Регион</label>
            <div class="col-md-8">
              <select class="form-control edit-client-popup-region">`;
      for (var key in crm.regions) {
        HTML += '<option value="' + crm.regions[key].name + '">' + crm.regions[key].name + '</option>';
      }
      HTML += `</select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Город</label>
            <div class="col-md-8">
              <input type="text" class="form-control edit-client-popup-city" value="${self.city}">
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Пол</label>
            <div class="col-md-8">
              <select class="form-control edit-client-popup-sex">`;
      sexArr.forEach(sex => {
        if (sex.value === self.sex.value) {
          HTML += `<option data-sex="${self.sex.value}"selected>${self.sex.name}</option>`;
        } else {
          HTML += `<option data-sex="${sex.value}">${sex.name}</option>`;
        }
      });
      HTML += `</select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Дети есть?</label>
            <div class="col-md-8">
              <select class="form-control edit-client-popup-child">`;
      childArr.forEach(function (child) {
        if (child.value === self.child.value) {
          HTML += `<option data-child="${self.child.value}" selected>${self.child.name}</option>`;
        } else {
          HTML += `<option data-child="${child.value}">${child.name}</option>`;
        }
      });
      HTML += `</select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-4 control-label">Комментарий</label>
            <div class="col-md-8">
              <textarea class="form-control edit-client-popup-info" rows="5">${self.info}</textarea>
            </div>
          </div>
        </form>
        <button class="btn btn-primary edit-client-popup-btn" style="margin:20px 30px; float:right;">Изменить</button>
      </div>`;
      let editClientPopup = popup(HTML, {
        class: 'edit-client-popup',
        radius: '5px'
      });
      $('.edit-client-datepicker').datetimepicker({
        format: 'DD/MM/YYYY'
      });
      let confirmBtn = document.querySelector('.edit-client-popup-btn');
      confirmBtn.addEventListener('click', function () {
        let name = document.querySelector('.edit-client-popup-name');
        let birthday = document.querySelector('.edit-client-popup-birthday');
        let passport = document.querySelector('.edit-client-popup-passport');
        let phone = document.querySelector('.edit-client-popup-phone');
        let email = document.querySelector('.edit-client-popup-email');
        let city = document.querySelector('.edit-client-popup-city');
        let sex = document.querySelector('.edit-client-popup-sex');
        let child = document.querySelector('.edit-client-popup-child');
        let info = document.querySelector('.edit-client-popup-info');
        let region = document.querySelector('.edit-client-popup-region');
        self.name = name.value;
        self.birthday = birthday.value;
        self.passport = passport.value;
        self.phone = phone.value;
        self.email = email.value;
        self.city = city.value;
        self.sex.value = sex.options[sex.selectedIndex].dataset.sex;
        self.sex.name = sex.options[sex.selectedIndex].innerHTML;
        self.child.value = child.options[child.selectedIndex].dataset.child;
        self.child.name = child.options[child.selectedIndex].innerHTML;
        self.info = info.value;
        self.region = region.value;
        editClientPopup.remove();
        loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
          background: 'transparent'
        });

        mn.object('clients').elements.edit({
          elements: [
            [self.id, self.hash]
          ],
          properties: {
            name: self.name,
            birthday: self.birthday,
            passport: self.passport,
            phone: self.phone,
            email: self.email,
            city: self.city,
            sex: self.sex.value,
            child: self.child.value,
            info: self.info,
            region: region.value
          },
          callback: function (response) {
            page.reDrawClients();
            page.reDrawReservations();
            loadingPopup.remove();
          }
        });
      });
    });
  }
  delete(btn) {
    let self = this;
    btn.addEventListener('click', () => {
      let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Вы уверены?</h4><div style="text-align:center;padding:30px;"><button class="btn btn-danger delete-client-popup-yes-btn">Да</button><button class="btn btn-primary delete-client-popup-no-btn">Нет</button></div>`;
      let confirmPopup = popup(HTML, {
        class: 'confirm-delete-client-popup',
        radius: '5px'
      });
      let yesBtn = document.querySelector('.delete-client-popup-yes-btn');
      let noBtn = document.querySelector('.delete-client-popup-no-btn');
      noBtn.addEventListener('click', function () {
        confirmPopup.remove();
      });
      yesBtn.addEventListener('click', function () {
        confirmPopup.remove();
        loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
          background: 'transparent'
        });
        let reservationId, reservationHash;
        if (crm.reservations) {
          crm.reservations.forEach((reservation, i) => {
            if (reservation.client.id = self.id) {
              reservationId = reservation.id;
              reservationHash = reservation.hash;
              crm.reservations.splice(i, 1);
            }
          });
        }
        Promise.all([
          new Promise((resolve, reject) => {
            mn.object('reservation').elements.delete({
              elements: [
                [reservationId, reservationHash]
              ],
              callback: (response) => {
                resolve(response);
              }
            });
          }),
          new Promise((resolve, reject) => {
            mn.object('clients').elements.delete({
              elements: [
                [self.id, self.hash]
              ],
              callback: (response) => {
                resolve(response);
              }
            });
          })
        ]).then((results) => {
          crm.clients.forEach((client, i) => {
            if (client.id === self.id) {
              crm.clients.splice(i, 1);
            }
          });
          page.reDrawClients();
          page.reDrawReservations();
          loadingPopup.remove();
        });
      });
    });
  }
  showReservations(btn) {
    if (this.client_reservations) {
      let self = this;
      btn.addEventListener('click', function () {
        let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Брони клиента</h4>
      <div style="padding:15px">
        <table style="width:600px;border:1px solid #DBE0E4">
          <tr>
            <th>Год</th>
            <th>Приезд</th>
            <th>Отъезд</th>
            <th>Предоплата</th>
          </tr>`;
        self.client_reservations.forEach((clientReservation) => {
          HTML += `<tr>
          <td>${clientReservation.year}</td>
          <td>${clientReservation.arrival}</td>
          <td>${clientReservation.departure}</td>
          <td>${clientReservation.pay.name}</td>
        </tr>`;
        });
        HTML += `</table></div>`;
        var clientReservationsPopup = popup(HTML, {
          class: 'client-reservations-popup',
          radius: '5px'
        });
      });
    }
  }
}