import crm from './crm';
import Client from './Client';
import popup from './lib/popup';
import xhr from './lib/xhr';

export default {
  reservationsContainer: document.getElementById('mp-reservations-block'),
  clientsContainer: document.getElementById('clients-container'),
  totalClientPanel: document.querySelector('.total-clients-panel'),
  inhabitedClientPanel: document.querySelector('.inhabited-client-panel'),
  bookedClientPanel: document.querySelector('.booked-client-panel'),
  roomsContainer: document.getElementById('rooms-container'),
  maleFemaleChart: null,
  reservationChart: null,
  regionsChart: null,



  drawReservations() {
    if (crm.reservations) {
      crm.reservations.forEach(reservation => {
        this.reservationsContainer.appendChild(reservation.draw());
      });
    }
  },
  reDrawReservations() {
    this.reservationsContainer.innerHTML = '';
    this.drawReservations();
  },
  dashBoardInit() {
    if (crm.reservations) {
      let now = new Date();
      let currentMonth = now.getMonth();
      let currentYear = now.getFullYear();

      let reservationDates = [];

      crm.reservations.forEach(function (item) {
        let arr = item.arrival.split('/');
        if (parseInt(arr[1]) === (currentMonth + 1) && parseInt(arr[2]) === currentYear) {
          reservationDates.push({
            day: parseInt(arr[0]),
            month: parseInt(arr[1]),
            year: parseInt(arr[2])
          });
        }
      });
      let data = [];
      for (let i = 1; i <= 30; ++i) {
        data.push({
          x: i,
          y: 0
        });
      }
      data.forEach(function (item) {
        reservationDates.forEach(function (data) {
          if (item.x === data.day) {
            item.y = ++item.y;
          }
        });
      });

      app_demo_dashboard.rickshaw(data);
      app_demo_dashboard.map();
    }
  },



  calendar() {
    $('#calendar').fullCalendar({
      locale: 'ru',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      buttonIcons: {
        prev: ' icon-chevron-left',
        next: ' icon-chevron-right',
        prevYear: ' icon-chevron-left',
        nextYear: ' icon-chevron-right'
      },
      eventRender: function (event, element) {
        element.click(function () {
          let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">бронь</h4>
          <div style="padding:15px">
            <table style="width:600px;border:1px solid #DBE0E4">
              <tr>
                <th>Клиент</th>
                <th>Приезд</th>
                <th>Отъезд</th>
                <th>Статус</th>
              </tr>`;
          crm.reservations.forEach(reservation => {
            if (reservation.id === event.id) {
              HTML += `<tr>
                <td>${reservation.client.name}</td>
                <td>${reservation.arrival}</td>
                <td>${reservation.departure}</td>
                <td>${reservation.status.name}</td>
              </tr>`;
            }
          });
          HTML += `</table></div>`;
          var calendarReservationPopup = popup(HTML, {
            class: 'calendar-reservation-popup',
            radius: '5px'
          });
        });
      }
    });
  },
  initCalendar() {
    if (crm.reservations) {
      let events = [];
      crm.reservations.forEach(reservation => {
        let startSplit = reservation.arrival.split('/');
        let endSplit = reservation.departure.split('/');
        events.push({
          title: reservation.room.name,
          start: startSplit[2] + '-' + startSplit[1] + '-' + startSplit[0] + 'T12:00:00',
          end: endSplit[2] + '-' + endSplit[1] + '-' + endSplit[0] + 'T12:00:00',
          className: "event-cls",
          id: reservation.id
        });
      });
      $('#calendar').fullCalendar('addEventSource', events);
    }
  },
  reInitCalendar() {
    $('#calendar').fullCalendar('removeEvents');
    this.initCalendar();
  },
  initCalendarRoomButtons() {
    let calendarButtonsContainer = document.getElementById('calendar-room-btns-container');
    crm.rooms.forEach(room => {
      let li = document.createElement('li');
      li.classList.add('calendar-room-btn');
      li.dataset.id = room.id;
      li.dataset.hash = room.hash;
      let a = document.createElement('a');
      a.innerHTML = room.name;
      li.appendChild(a);
      li.addEventListener('click', () => {
        if (crm.reservations) {
          let events = [];
          crm.reservations.forEach(reservation => {
            if (reservation.room.id === li.dataset.id) {
              let startSplit = reservation.arrival.split('/');
              let endSplit = reservation.departure.split('/');
              events.push({
                title: reservation.room.name,
                start: startSplit[2] + '-' + startSplit[1] + '-' + startSplit[0] + 'T12:00:00',
                end: endSplit[2] + '-' + endSplit[1] + '-' + endSplit[0] + 'T12:00:00',
                className: "event-cls",
                id: reservation.id
              });
            }
          });
          $('#calendar').fullCalendar('removeEvents');
          $('#calendar').fullCalendar('addEventSource', events);
        }
      });
      calendarButtonsContainer.appendChild(li);
    });
  },
  initCalendarAllRoomBtn() {
    let self = this;
    let btn = document.querySelector('.calendar-all-rooms-btn');
    btn.addEventListener('click', () => {
      self.reInitCalendar();
    });
  },



  drawClients() {
    if (crm.clients) {
      crm.clients.forEach(client => {
        let arr = client.draw();
        this.clientsContainer.appendChild(arr[0]);
        this.clientsContainer.appendChild(arr[1]);
      });
    }
  },
  reDrawClients() {
    this.clientsContainer.querySelectorAll('.client-item-for-remove').forEach(item => {
      item.remove();
    });
    this.drawClients();
  },
  reDrawFilteredOrSearchedClients(clients) {
    if (clients) {
      this.clientsContainer.querySelectorAll('.client-item-for-remove').forEach(item => {
        item.remove();
      });
      clients.forEach(client => {
        let arr = client.draw();
        this.clientsContainer.appendChild(arr[0]);
        this.clientsContainer.appendChild(arr[1]);
      });
    }
  },
  addClientBtnsInit() {
    let addClientBtns = document.querySelectorAll('.add-client-btn');
    addClientBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        Client.add();
      });
    });
  },
  totalClientsPanelInit() {
    if (crm.clients) {
      this.totalClientPanel.innerHTML = crm.clients.length;
    }
  },
  inhabitedClientPanelInit() {
    if (crm.reservations) {
      let counter = 0;
      crm.reservations.forEach(item => {
        if (item.status.value === 'inhabited') {
          counter += item.adults * 1;
        }
      });
      this.inhabitedClientPanel.innerHTML = counter;
    }
  },
  bookedClientPanelInit() {
    if (crm.reservations) {
      let counter = 0;
      crm.reservations.forEach(item => {
        if (item.status.value === 'booked') {
          counter += item.adults * 1;
        }
      });
      this.bookedClientPanel.innerHTML = counter;
    }
  },
  searchClientsInit() {
    let searchClientBtn = document.querySelector('.search-client-btn');
    let self = this;
    crm.searchedClients = crm.clients;
    searchClientBtn.addEventListener('click', () => {
      crm.searchedClients = self.makeSearch();
      self.reDrawFilteredOrSearchedClients(crm.searchedClients);
    });

  },
  makeSearch() {
    let searchClientSelect = document.querySelector('.search-client-select');
    let searchClientInput = document.querySelector('.search-client-input');
    let searchSelect = searchClientSelect.value;
    let searchText = searchClientInput.value.toLowerCase();

    let sexRadioBtns = document.querySelectorAll('.app-radio input[name="radio-sex"]');
    let payRadioBtns = document.querySelectorAll('.app-radio input[name="radio-pay"]');
    let sexRadioBtnValue;
    let payRadioBtnValue;
    sexRadioBtns.forEach(_btn => {
      if (_btn.checked) {
        sexRadioBtnValue = _btn.value;
      }
    });
    payRadioBtns.forEach(_btn => {
      if (_btn.checked) {
        payRadioBtnValue = _btn.value;
      }
    });

    let searchedClients = [];

    if (!searchText && sexRadioBtnValue === 'all' && payRadioBtnValue === 'all') {
      // this.reDrawClients();
      return crm.clients;
    } else {
      crm.clients.forEach(client => {
        searched = false;
        if (!searchText) {
          searched = true;
        } else if (searchSelect === 'reservation') {
          if (client.client_reservations.length > 0) {
            let flag = true;
            client.client_reservations.forEach(client_reservation => {
              if (parseInt(client_reservation.year) > parseInt(searchText)) {
                flag = false;
              }
            });
            if (flag) {
              searched = true;
            }
          }
        } else {
          if (client[searchSelect].indexOf(searchText) !== -1) {
            searched = true;
          }
        }
        if (searched) {
          sexSearched = false;
          if (client.sex.value === sexRadioBtnValue || sexRadioBtnValue === 'all') {
            sexSearched = true;
          }
          if (sexSearched) {
            let flag = false;
            let isPay = false;
            if (payRadioBtnValue === 'all') {
              flag = true;
            } else if (client.client_reservations.length > 0 && payRadioBtnValue === 'y') {
              client.client_reservations.forEach(client_reservation => {
                if (client_reservation.reservation && client_reservation.reservation.pay === payRadioBtnValue) {
                  flag = true;
                }
              });
            } else if (client.client_reservations.length > 0 && payRadioBtnValue === 'n') {
              client.client_reservations.forEach(client_reservation => {
                if (client_reservation.reservation && client_reservation.reservation.pay === 'y') {
                  isPay = true;
                }
              });
              if (!isPay) {
                flag = true;
              }
            }
            if (flag) {
              searchedClients.push(client);
            }
          }
        }
      });
      return searchedClients;
    }
  },



  drawRooms() {
    if (crm.rooms) {
      crm.rooms.forEach(room => {
        this.roomsContainer.innerHTML += room.draw();
      });
    }
  },
  reDrawRooms() {
    this.roomsContainer.innerHTML = '';
    this.drawClients();
  },



  drawBudget() {
    let previousYear = document.querySelector('.previous-year');
    let previousYearIncome = document.querySelector('.previous-year-income');
    let previousYearOutgo = document.querySelector('.previous-year-outgo');
    let currentYear = document.querySelector('.current-year');
    let currentYearIncome = document.querySelector('.current-year-income');
    let currentYearOutgo = document.querySelector('.current-year-outgo');
    let now = new Date();
    let year = now.getFullYear();
    currentYear.innerHTML = year;
    previousYear.innerHTML = year - 1;
    if (crm.budget) {
      let incomeCurrentSum = outgoCurrentSum = incomePreviousSum = outgoPreviousSum = 0;
      crm.budget.forEach(item => {
        let itemYear = parseInt(item.date.split('.')[2]);
        if (itemYear === year) {
          switch (item.type.value) {
            case 'agency':
            case 'breakfast':
            case 'sale':
            case 'other':
              outgoCurrentSum += parseInt(item.sum);
              break;
            default:
              incomeCurrentSum += parseInt(item.sum);
              break;
          }
        } else if (itemYear === (year - 1)) {
          switch (item.type.value) {
            case 'agency':
            case 'breakfast':
            case 'sale':
            case 'other':
              outgoPreviousSum += parseInt(item.sum);
              break;
            default:
              incomePreviousSum += parseInt(item.sum);
              break;
          }
        }
      });
      previousYearIncome.innerHTML = incomePreviousSum;
      previousYearOutgo.innerHTML = outgoPreviousSum;
      currentYearIncome.innerHTML = incomeCurrentSum;
      currentYearOutgo.innerHTML = outgoCurrentSum;
    }
  },
  reDrawBudget() {
    this.drawBudget();
  },
  addBudgetBtnInit() {
    let self = this;
    let addBtn = document.querySelector('.budget-btn');
    addBtn.addEventListener('click', () => {
      let select = document.querySelector('.budget-type select');
      let sum = document.querySelector('.budget-sum');
      let loadingPopup = popup('<img src="crm/img/loading.gif" style="width:50px;">', {
        background: 'transparent'
      });
      mn.object('budget').elements.add({
        properties: {
          type: select.value,
          sum: sum.value
        },
        callback: response => {
          let obj = JSON.parse(response);
          let now = new Date();
          if (crm.budget) {
            crm.budget.push({
              id: obj.id_element,
              hash: obj.hash_element,
              sum: sum.value,
              type: {
                name: select.options[select.selectedIndex].innerHTML,
                value: select.value
              },
              date: now.getDate() + '.' + now.getMonth() + '.' + now.getFullYear()
            });
          }
          self.reDrawBudget();
          loadingPopup.remove();
        }
      });
    });
  },
  maleFemaleInit() {
    if (crm.clients) {
      let male = 0;
      let female = 0;
      let undef = 0;
      crm.clients.forEach(client => {
        if (client.sex.value === 'm') {
          ++male;
        } else if (client.sex.value === 'f') {
          ++female;
        } else {
          ++undef;
        }
      });
      let ctx = document.getElementById("male-female-chart");
      this.maleFemaleChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: [
            `мужчины (${male})`,
            `женщины (${female})`,
            `неопределено (${undef})`
          ],
          datasets: [{
            data: [male, female, undef],
            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"]
          }]
        }
      });
    }
  },
  reservationStatisticInit() {
    let now = new Date();
    let statistic = {
      march: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0
    };
    if (crm.clientReservations) {
      let endOfMarch = new Date(now.getFullYear(), 3, 0);
      let endOfApril = new Date(now.getFullYear(), 4, 0);
      let endOfMay = new Date(now.getFullYear(), 5, 0);
      let endOfJune = new Date(now.getFullYear(), 6, 0);
      let endOfJuly = new Date(now.getFullYear(), 7, 0);
      let endOfAugust = new Date(now.getFullYear(), 8, 0);
      crm.clientReservations.forEach(item => {
        let dateSplit = item.date.split('.');
        let reservationDate = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
        if (reservationDate.getFullYear() === now.getFullYear()) {
          if (reservationDate.valueOf() < endOfMarch.valueOf()) {
            ++statistic.march;
          }
          if (reservationDate.valueOf() < endOfApril.valueOf()) {
            ++statistic.april;
          }
          if (reservationDate.valueOf() < endOfMay.valueOf()) {
            ++statistic.may;
          }
          if (reservationDate.valueOf() < endOfJune.valueOf()) {
            ++statistic.june;
          }
          if (reservationDate.valueOf() < endOfJuly.valueOf()) {
            ++statistic.july;
          }
          if (reservationDate.valueOf() < endOfJuly.valueOf()) {
            ++statistic.august;
          }
        }
      });
    }
    let ctx = document.getElementById("reservations-chart");
    this.reservationChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [`Март`, `Апрель`, `Май`, `Июнь`, `Июль`, `Август`],
        datasets: [{
          label: `Бронирование ${now.getFullYear()}`,
          data: [
            statistic.march,
            statistic.april,
            statistic.may,
            statistic.june,
            statistic.july,
            statistic.august
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          "fill": false,
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            }
          }]
        }
      }
    });
  },
  regionStatisticInit() {
    let statisticName = [];
    let statisticAmount = [];
    let statisticColor = [];
    let statisticBorderColor = [];
    if (crm.clients) {
      for (const region in crm.regions) {
        if (crm.regions.hasOwnProperty(region)) {
          const regionName = crm.regions[region].name;
          let amount = 0;
          crm.clients.forEach(client => {
            if (client.region === regionName) {
              ++amount;
            }
          });
          // statistic.push({
          //   name: regionName,
          //   amount: amount,
          //   color: 'rgba(255, 99, 132, 0.2)'
          // });
          statisticName.push(`${regionName}(${amount})`);
          statisticAmount.push(amount);
          const colorOne = Math.floor(Math.random() * 255);
          const colorTwo = Math.floor(Math.random() * 255);
          const colorThree = Math.floor(Math.random() * 255);
          statisticColor.push(`rgba(${colorOne}, ${colorTwo}, ${colorThree}, 0.2)`);
          statisticBorderColor.push(`rgb(${colorOne}, ${colorTwo}, ${colorThree})`);
        }
      }
    }
    let ctx = document.getElementById("regions-chart");
    this.reservationChart = new Chart(ctx, {
      type: "polarArea",
      data: {
        labels: statisticName,
        datasets: [{
          // label: "My First Dataset",
          data: statisticAmount,
          backgroundColor: statisticColor,
          borderColor: statisticBorderColor,
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: `Регионы`
        },
      }
    });
  },



  distributionTwoYearInit() {
    mn.object('distributions').elements.get({
      query: 'w=4',
      template: '[[text]]',
      callback: function (responseText) {
        let now = new Date();
        let currentYear = now.getFullYear();
        let clientsArr = [];
        if (crm.clientDistributions && crm.clients) {
          crm.clients.forEach(client => {
            let flag = true;
            crm.clientDistributions.forEach(distribution => {
              if (distribution.client === client.id) {
                flag = false;
              }
            });
            if (flag) {
              clientsArr.push(client);
            }
          });
        } else if (crm.clients) {
          clientsArr = crm.clients;
        } else {
          return;
        }
        let distribClientArr = [];
        clientsArr.forEach(client => {
          let flag = true;
          if (client.client_reservations.length > 0) {
            client.client_reservations.forEach(reservation => {
              if ((currentYear - parseInt(reservation.year)) < 2) {
                flag = false;
              }
            });
          } else {
            flag = false;
          }
          if (flag) {
            distribClientArr.push(client);
          }
        });
        console.log(distribClientArr);

        if (distribClientArr.length > 0) {
          function sendEmail(distribClientArr, i) {
            if (i < distribClientArr.length) {
              var text = responseText.replace(/\[\[name\]\]/, distribClientArr[i].name);
              text = encodeURI(text);

              let url = `?page=client_distribution&property:client=${distribClientArr[i].id}&property:type=two_years&text=${text}`;

              xhr(url, null, response => {
                ++i;
                sendEmail(distribClientArr, i);
              });
            } else {
              return;
            }
          }
          sendEmail(distribClientArr, 0);
        }
      }
    });
  },
  distributionPrependSevenDays() {
    mn.object('distributions').elements.get({
      query: 'w=6',
      template: '[[text]]',
      callback: function (responseText) {
        let now = new Date();
        // now.setDate(now.getDate() - 7);
        // let SevenDay = now;

        console.log('crm.reservations');
        console.log(crm.reservations);

        let distribReservations = [];
        if (crm.reservations) {
          crm.reservations.forEach(reservation => {
            arrivalSplit = reservation.arrival.split('/');
            let arrivalDate = new Date(arrivalSplit[2], arrivalSplit[1] - 1, arrivalSplit[0]);
            arrivalDate.setDate(arrivalDate.getDate() - 7);
            if (reservation.seven_sended !== 'y' && arrivalDate.valueOf() < now.valueOf()) {
              distribReservations.push(reservation);
              console.log('arrivalDate');
              console.log(arrivalDate);
              console.log('now');
              console.log(now);
              console.log('reservation');
              console.log(reservation);
            }
          });
        }

        console.log('distribReservations');
        console.log(distribReservations);


        if (distribReservations.length > 0) {
          function sendEmail(distribReservations, i) {
            if (i < distribReservations.length) {
              var text = responseText.replace(/\[\[name\]\]/, distribReservations[i].client.name);
              text = encodeURI(text);

              let url = `?page=client_distribution&property:client=${distribReservations[i].client.id}&property:type=other&text=${text}`;

              console.log(distribReservations[i].id);
              

              console.log('url');
              console.log(url);
              xhr(url, null, response => {
                mn.object('reservation').elements.edit({
                  elements: [
                    [
                      distribReservations[i].id,
                      distribReservations[i].hash
                    ]
                  ],
                  properties: {
                    seven_sended: 'y'
                  },
                  callback: function (response) {console.log('edit sended');}
                });
                ++i;
                sendEmail(distribReservations, i);
              });
            } else {
              return;
            }
          }
          sendEmail(distribReservations, 0);
        }
      }
    });
  },
  searchedClientsDistributionInit() {
    let btn = document.querySelector('.distribution-btn');
    btn.addEventListener('click', () => {
      mn.object('distributions').elements.get({
        query: 'w-min=1&w-max=3',
        template: '{"id":"[[id_element]]", "hash":"[[hash_element]]", "subject":"[[subject]]", "text":"[[text]]"}',
        separator: ',',
        count: 1000,
        sort: 'w.min',
        wrapper: '[|elements|]',
        callback: function (response) {
          let textArr = JSON.parse(response);
          console.log(textArr);

          let HTML = `<h4 style="padding:15px;background-color:#292f43;height:60px;line-height:30px;color:#fff;border-radius:5px 5px 0 0">Рассылка</h4>
          <div style="padding:30px;text-align:center;">
              <select class="form-control distribution-select" style="margin-top:50px">`;
          textArr.forEach(item => {
            HTML += `<option data-id="${item.id}">${item.subject}</option>`;
          });
          HTML += `</select>
          <textarea class="form-control distribution-textarea" rows="7" style="margin-top:20px">${textArr[0].text}</textarea>
          <button type="button" class="btn btn-primary distribution-confirm-btn" style="margin-top:20px;">Запустить</button>
          </div>`;
          let distributionPopup = popup(HTML, {
            class: 'distribution-popup',
            radius: '5px'
          });
          let select = document.querySelector('.distribution-select');
          let textarea = document.querySelector('.distribution-textarea');
          select.addEventListener('change', function () {
            let id = select.options[select.selectedIndex].dataset.id;
            textArr.forEach(function (item) {
              if (item.id === id) {
                textarea.value = item.text;
              }
            });
          });
          let distributionConfirmBtn = document.querySelector('.distribution-confirm-btn');
          distributionConfirmBtn.addEventListener('click', () => {
            let HTML = `<div class="counter-block" style="width:100px;height:100px;line-height:100px;text-align:center;font-size:20px;font-weight:bold;">
              <span>0</span>
              <span> / </span>
              <span></span>
            </div>`;
            let counterPopup = popup(HTML, {
              class: 'counter-popup',
              radius: '50%'
            });
            let counterFirst = counterPopup.querySelector('.counter-block span:first-child');
            let counterLast = counterPopup.querySelector('.counter-block span:last-child');

            counterLast.innerText = crm.searchedClients.length;

            let textRaw = textarea.value;
            console.log('text');
            console.log(textRaw);

            function sendEmail(distribClientArr, i) {
              if (i < distribClientArr.length) {
                let text = textRaw.replace(/\[\[name\]\]/, distribClientArr[i].name);
                text = encodeURI(text);

                let url = `?page=client_distribution&property:client=${distribClientArr[i].id}&property:type=other&text=${text}`;

                xhr(url, null, response => {
                  counterFirst.innerText = i + 1;
                  ++i;
                  sendEmail(distribClientArr, i);
                });
              } else {
                counterPopup.remove();
                distributionPopup.remove();

                return;
              }
            }
            console.log(crm.searchedClients);
            sendEmail(crm.searchedClients, 0);
          });
        }
      });
    });

  }
};